
import { Component, Prop } from "vue-property-decorator";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { BaseForm } from "./BaseForm";
import CountryFlag from "vue-country-flag";

@Component({
	components: {
		VuePhoneNumberInput,
		CountryFlag,
	},
})
export default class ForgotPasswordForm extends BaseForm {
	public labels: Record<string, string> = {
		phone: "Phone Number",
		email: "Email",
		country: "Country/Region",
	};

	public phoneInput = "";

	@Prop({ default: null })
	public instrument!: string;

	public altInstrument = "email";

	@Prop({ required: true, default: "NG" })
	public defaultCountry!: string;

	@Prop({ required: true, default: {} })
	public countries!: Record<string, string>;

	@Prop({ required: true })
	public signinUrl!: string;

	@Prop({ required: false })
	public referrerUrl?: string;

	public model: Record<string, any> = {
		instrument: this.instrument,
		phone: null,
		country: this.defaultCountry,
		email: null,
		puzzle: null,
	};

	get referred() {
		return this.referrerUrl && this.$isInternalUrl(this.referrerUrl);
	}

	get backUrl() {
		return this.referrerUrl && this.referred ? this.referrerUrl : this.signinUrl;
	}

	public onPhoneNumberUpdate(payload: any) {
		this.model.phone = payload.formattedNumber;
		this.model.country = payload.countryCode;
	}

	public empty(exclude: string[] = []) {
		Object.keys(this.model).forEach((key) => {
			if (exclude.includes(key)) {
				return;
			}
			this.model[key] = null;
		});
		this.model.instrument = this.instrument;
		// this.model.country = this.defaultCountry;
	}

	public toggle(e: any) {
		e.preventDefault();
		this.instrument = this.altInstrument;
		this.empty(["puzzle"]);
		this.$nextTick(() => {
			this.$formObserver.reset();
		});
	}

	public async send() {
		const response = await this.$api.post(this.action, this.model);
		if (!response.ok) {
			return this.handleError(response);
		}
		this.$alert.clear();
		this.$emit("done", response.body);
	}
}
